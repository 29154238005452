import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  refAddress: "",
  walletID: "",
  totalStake: 0,
  claimedReward: 0,
  alphaId: 0,
  allStakes: [],
};

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setRefAddress(state, action) {
      state.refAddress = action.payload;
    },
    setWalletId(state, action) {
      state.walletID = action.payload;
    },
    setTotalStake(state, action) {
      state.totalStake = action.payload;
    },
    setClaimedReward(state, action) {
      state.claimedReward = action.payload;
    },
    setAlphaId(state, action) {
      state.alphaId = action.payload;
    },
    setAllStakes(state, action) {
      state.allStakes = action.payload;
    },
  },
});

export const {
  setRefAddress,
  setWalletId,
  setTotalStake,
  setClaimedReward,
  setAlphaId,
  setAllStakes,
} = walletSlice.actions;

export default walletSlice.reducer;
