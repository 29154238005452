import React from 'react';
import { Sparklines, SparklinesBars } from 'react-sparklines';

const sampleData = [100, 120, 130, 140, 150, 160,170,180];

const Dchart1 = () => {
  return (
    <div style={{ width: '100%' }}>
      <Sparklines data={sampleData}>
        <SparklinesBars style={{ fill: "#37373f", width: "20" }} />
      </Sparklines>
    </div>
  );
};

export default Dchart1;

